@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$black: #000;
$white: #fff;
$purple: #7D00FE;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$primary: $black;
$info: $white;
$link: $purple;

// Update some of Bulma's component variables
$input-shadow: none;

@import '../node_modules/bulma/bulma.sass';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
